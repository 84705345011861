import { StyleSheet } from "react-native";
import { Avatar, BorderRadiuses, Colors, Image, ListItem, Text, View } from "react-native-ui-lib";
import { UserType } from "../../lib/types/entities";

interface Props extends UserType {
  big: boolean;
}

export function GoalRow({ goal_text }: Props) {
  return (
    <View
      paddingH-10
      paddingV-5
      row
      flex
      centerV
    >
    <View marginL-5>
      <Text text90>
        {goal_text}
      </Text>
    </View>
  </View>
  )
}

const styles = StyleSheet.create({

});