import { useEffect, useState } from "react"
import * as ImagePicker from 'expo-image-picker';
import { Avatar, Text, TextField, View } from "react-native-ui-lib"
import useData from "../../lib/data/useData"
import { Dimensions, Pressable, StyleSheet } from "react-native"
import { Divider } from "react-native-elements"
import { ButtonComponent } from "../../components/button"
import { useToast } from "react-native-toast-notifications"
import { supabase } from "../../lib/supabase"
import AvatarEmpty from '../../assets/empty_avatar.png'
import { decode } from 'base64-arraybuffer';

interface Link {
  type?: string,
  text: string,
  value: string
}

const windowWidth = Dimensions.get("window").width
const tinyScreen = windowWidth < 430
const desktopScreen = windowWidth > 768

export function EditProfileScreen({navigation, route}) {
  const { user, userSession, setUser } = useData()
  const [firstName, setFirstName] = useState<string>(user?.first_name || '')
  const [lastName, setLastName] = useState<string>(user?.last_name || '')
  const [presentation, setPresentation] = useState<string>(user?.presentation || '')
  const [type, setType] = useState<string>(user?.therapy_type || '')
  const [population, setPopulation] = useState<string>(user?.population || '')
  const [links, setLinks] = useState<Record<string, Link>>(user?.links)
  const [imageUrl, setImageUrl] = useState<string>()
  const [path, setPath] = useState<string>(user?.image)
  const [slug, setSlug] = useState<string>(user?.slug)
  const [loading, setLoading] = useState(false)
  const toast = useToast();
  
  useEffect(() => {
    const getImage = async () => {
      const { data } = await supabase.storage.from('avatars').getPublicUrl(path)
      setImageUrl(data?.publicUrl)
    }
    if (path) {
      getImage()
    }
  }, [path])

  useEffect(() => {
    const _slug = `${firstName}-${lastName}`
    if (_slug !== '-') {
      setSlug(_slug.toLowerCase().split(" ").join(""))
    }
  }, [firstName, lastName])


  const updateFirstNameHandler = (value: string) => {
    setFirstName(value)
  }

  const updateLastNameHandler = (value: string) => {
    setLastName(value)
  }

  const updateValueLinkHandler = (id: string, value: string) => {
    setLinks(prevState => {

      const link: Link = {
        ...prevState?.[id],
        value
      }

      return {
        ...prevState,
        [id]: link
      }
    })
  } 

  const updateTextLinkHandler = (id: string, text: string) => {
    setLinks(prevState => {

      const link: Link = {
        ...prevState?.[id],
        text
      }

      return {
        ...prevState,
        [id]: link
      }
    })
  }

  const newLinkHandler = () => {
    setLinks(prevState => {
      const id = prevState ? Object.keys(prevState).length + 1 : 1
      return {
        ...prevState,
        [id]: {}
      }
    })
  }

  

  async function saveDataHandler(_, pathAvatar = null) {
    setLoading(true)
    let functionToInvoke = 'save-data-user'

    if (!user) {
      functionToInvoke = 'new-data-user'
    }

    const { data, error } = await supabase.functions.invoke(functionToInvoke, {
      headers: { Authorization: `Bearer ${userSession?.access_token}` },
      body: { user: { first_name: firstName, last_name: lastName, presentation, links, image: pathAvatar || path, slug, population, therapy_type: type }}
    })

    if (error) {
      const message = 'Ocurrio un error, intenta nuevamente más tarde.'
      toast.show(message, {
        type: 'danger',
        duration: 5000
      });
    } else {
      setUser(data.user)
    }
    setLoading(false)
  }

  const disabled = loading || !firstName || !lastName || !slug

  return (
    <View padding-20 bg-white flex>
      <View center>
        <ImagePickerComponent userId={userSession?.user.id} setPath={setPath} saveDataHandler={saveDataHandler} imageUrl={imageUrl} user={user}/>
      </View>
      <View flex row={desktopScreen}>
        <View padding-10={desktopScreen}>

          <View row={!tinyScreen}>
            <View flex paddingR-10={!tinyScreen}>
              <TextField
                style={styles.input}
                label="Nombre"
                labelStyle={styles.label}
                onChangeText={updateFirstNameHandler}
                value={firstName}
                enableErrors
                validate={['required']}
                validationMessage={['Campo obligatorio']}
                maxLength={30}
              />
            </View>
            <View flex paddingL-10={!tinyScreen}>
              <TextField
                style={styles.input}
                label="Apellido"
                labelStyle={styles.label}
                onChangeText={updateLastNameHandler}
                value={lastName}
                enableErrors
                validate={['required']}
                validationMessage={['Campo obligatorio']}
                maxLength={30}
              />
            </View>
          </View>
          <View paddingB-10>
            <TextField
              style={styles.input}
              label="Especialidad"
              labelStyle={styles.label}
              onChangeText={setType}
              value={type}
              enableErrors
              validate={['required']}
              validationMessage={['Campo obligatorio']}
              maxLength={30}
            />
          </View>
          <View paddingB-10>
            <TextField
              style={styles.input}
              label="Población"
              labelStyle={styles.label}
              onChangeText={setPopulation}
              value={population}
              enableErrors
              maxLength={30}
            />
          </View>
          <View paddingB-10>
            <TextField
              style={styles.input}
              label="Tu presentación"
              labelStyle={styles.label}
              onChangeText={setPresentation}
              value={presentation}
              maxLength={150}
              showCharCounter
              multiline
              numberOfLines={3}
            />
          </View>
        </View>
        <View flex padding-10={desktopScreen}>
          <View paddingB-10 paddingT-20={!desktopScreen}>
            <Pressable onPress={() => copyToClipboard(`https://okwombat.com/perfil/${slug}`)}>
            <TextField
              style={styles.input}
              label="URL Wombat"
              placeholder={`https://okwombat.com/perfil/`}
              labelStyle={styles.label}
              onChangeText={setSlug}
              value={slug && `https://okwombat.com/perfil/${slug}`}
              maxLength={150}
              editable={false}
            />
            <Text style={styles.text}>Presiona para copiar</Text>
            </Pressable>
          </View>
          <Divider />
          <View>
            <View row spread paddingV-10>
              <Text style={styles.label}>Enlaces</Text>
              <Pressable style={[styles.buttonText, styles.buttonTextEnabled]} onPress={newLinkHandler}>
                <Text color="#fff">Añadir</Text>
              </Pressable>
            </View>
            {links && Object.keys(links)?.map(id => (<View row={!tinyScreen} paddingV-5 spread>
              <View flex paddingR-10={!tinyScreen}>
                <TextField
                  onChangeText={(value) => updateValueLinkHandler(id, value)}
                  value={links[id].value}
                  style={styles.input}
                  placeholder="Enlace"
                  maxLength={100}
                />
              </View>
              <View flex paddingL-10={!tinyScreen}>
                <TextField
                  onChangeText={(value) => updateTextLinkHandler(id, value)}
                  value={links[id].text}
                  style={styles.input}
                  placeholder="Título"
                  maxLength={50}
                />
              </View>
            </View>))}
          </View>
        </View>
      </View>
      <ButtonComponent text="Guardar" loading={disabled} onPress={saveDataHandler}/>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    fontFamily: 'Mukta Regular',
    paddingVertical: 20,
    paddingHorizontal: 5,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    color: '#919191',
    borderWidth: 1,
    fontSize: 18,
    borderRadius: 4
  },
  label: {
    fontFamily: 'Mukta Bold',
    color: '#717171',
    fontSize: 18,
  },
  text: {
    fontFamily: 'Mukta Regular',
    color: '#717171',
    fontSize: 15,
  },
  buttonText: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  buttonTextEnabled: {
    backgroundColor: '#377F67',
  },
})

const copyToClipboard = async str => {
  try {
    await navigator.clipboard.writeText(str);
    console.log("copied");
  } catch (error) {
    console.log(error);
  }
};

export function ImagePickerComponent({ userId, user, imageUrl, setPath, saveDataHandler }) {
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      quality: 0.8,
    });

    if (!result.canceled) {
      const uri = result.assets[0].uri
      const ext = getBase64ImageExtension(uri.split(',')[1])
      const filePath = `${userId}/avatar-${new Date().getTime()}.${ext}`
      const contentType = `image/${ext}`
      const response = await supabase.storage.from('avatars').upload(filePath, decode(uri.split(',')[1]), {
        contentType,
        cacheControl: '3600',
        upsert: true
      })
      if (response?.data?.path) {
        setPath(response?.data?.path)
        saveDataHandler({}, response?.data?.path)
      }
    }
  };

  const name = user?.first_name && user?.last_name && `${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`
  const image = imageUrl || (name ? '' : AvatarEmpty)

  return (
    <Pressable onPress={pickImage}>
      <Avatar source={{uri: image}} size={100} label={name} badgeProps={{
        customElement: <Text>Cambiar</Text>,
        size: 30,
        borderWidth: 1.5,
        borderColor: '#dadada',
        backgroundColor: '#fff',
      }}
      badgePosition='BOTTOM_RIGHT'
      />
    </Pressable>
  );
}

function getBase64ImageExtension(base64Data) {
  const supportedExtensions = {
    '/9j/': 'jpg',
    'iVBORw0KGg': 'png',
    'R0lGOD': 'gif',
    'Qk02': 'webp',
    'UklGR': 'svg',
  };

  for (const key in supportedExtensions) {
    if (base64Data.indexOf(key) === 0) {
      return supportedExtensions[key];
    }
  }

  // Si no se encuentra una coincidencia, puedes manejar casos adicionales o devolver una extensión predeterminada.
  return 'unknown';
}