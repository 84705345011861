import { StyleSheet, Image, FlatList, Pressable } from "react-native"
import { View, Text, Checkbox } from "react-native-ui-lib"

const Task = ({ title, name, patient, therapist }) => {
  return (
    <View
      paddingH-10
      paddingV-5
      row
      flex
      centerV
      >
      <Checkbox value={false} onValueChange={() => console.log('value changed')}/>

      <View column marginL-5>
        <Text text70>
          {title}
        </Text>
        <Text text80R>
          {name}
        </Text>
      </View>
      <View column marginL-5>
        <Text text70>
          {patient}
        </Text>
        <Text text80R>
          {therapist}
        </Text>
      </View>
    </View>
  )
} 

export function TasksScreen({navigation, route}) {
  const tasks = []

  return <View style={styles.container}>
    <View style={styles.header}>
      <Text style={styles.title}>Tareas</Text>
      <Pressable onPress={() => { navigation.navigate("NewTask", { team: '' }) }}>
        <Text style={[styles.inviteText, styles.inviteTextEnabled]}>Crear</Text>
      </Pressable>
    </View>
    <View style={{ flexDirection: 'row', gap: 2 }}>
      <FlatList
        style={{ width: "100%" }}
        data={tasks}
        renderItem={({ item }) => <Task {...item}/>}
        keyExtractor={(item) => item?.id}
      />
    </View>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontFamily: 'Mukta Bold',
    textAlign: 'center',
  },
  text: {
    fontSize: 18,
    fontFamily: 'Mukta Regular',
  },
  inviteText: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  inviteTextEnabled: {
    backgroundColor: '#377F67',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})