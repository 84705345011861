//import { setupURLPolyfill } from 'react-native-url-polyfill';

//setupURLPolyfill();

//import * as SecureStore from "expo-secure-store"
import { createClient } from '@supabase/supabase-js'
import AsyncStorage from "@react-native-async-storage/async-storage";

/*const ExpoSecureStoreAdapter = {
  getItem: (key: string) => {
    return SecureStore.getItemAsync(key)
  },
  setItem: (key: string, value: string) => {
    SecureStore.setItemAsync(key, value)
  },
  removeItem: (key: string) => {
    SecureStore.deleteItemAsync(key)
  },
}*/

const supabaseUrl = 'https://cetfcpqnmdzleubyhdeu.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNldGZjcHFubWR6bGV1YnloZGV1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEyNTA3NTEsImV4cCI6MTk5NjgyNjc1MX0.VGIBJs8zbbxtTBf8q9oNn43veS6kDWsckJJY9EAXvFM'

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    //storage: ExpoSecureStoreAdapter as any,
    storage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
})