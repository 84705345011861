import { useState } from "react";
import { FormPatient, InputsPatient } from "../../components/form-patient";
import { ScrollView } from "react-native";
import { PatientType } from "../../lib/types/entities";
import { supabase } from "../../lib/supabase";
import useData from "../../lib/data/useData";
import Toast from 'react-native-root-toast';
import { usePatientStore } from "../../lib/data/stores/patients";


export default function EditPatientScreen({navigation, route}) {
  const [loading, setLoading] = useState(false)
  const { patients, setPatients } = usePatientStore()
  const { user, userSession } = useData()
  const patient = route.params.patient as PatientType

  async function onSubmitHandler(inputs: InputsPatient) {
    if (!loading) {
      setLoading(true)

      const { team, id, ...patient } = inputs

      const { data, error } = await supabase.functions.invoke('patient', {
        headers: { Authorization: `Bearer ${userSession?.access_token}` },
        body: { patient, pid: id, method: 'PUT' }
      })

      if (!error) {
        /*Toast.show('Se modificó el paciente correctamente', {
          duration: Toast.durations.LONG,
        });*/
        console.log(patients, data)
        setPatients(patients.map(p => p.id === id ? data.patients : p))
        navigation.replace("DetailPatient", { patient: data.patients })
      } else {
        {/*toast({
          title: 'Ocurrio un error, intenta nuevamente más tarde.',
          status: 'error',
        })*/}
        console.log(error)
        setLoading(false)
      }
    }
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      <FormPatient onSubmit={onSubmitHandler} patient={patient} loading={loading}/>
    </ScrollView>
  )
}
