import { useEffect, useState } from "react"
import { Alert, Image, Pressable, StyleSheet, Text, View } from 'react-native'
import { TextInput } from 'react-native'
import { supabase } from "../../lib/supabase"
import { Button } from "react-native-elements"
import Toast from 'react-native-root-toast';
import { ButtonComponent } from "../../components/button"
import { set } from "react-native-reanimated"

export default function ValidateScreen({ navigation, route }) {
  const [loading, setLoading] = useState(true)
  const email = route.params.email

  const loginHandler = () => {
    navigation.replace("Login", { email })
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 10000)
  }, [])

  return (
    <View style={styles.container}>
      <Image source={require('../../assets/ilus_2.png')} style={{ alignSelf: 'center', height: 258 - 20, width: 247 - 20 }}  resizeMode='contain'/>
      <Text style={styles.title}>Enviamos un correo a {email} para validar tu cuenta</Text>

      
      <ButtonComponent text="Ingresar" loading={loading} onPress={loginHandler}/>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 50,
    backgroundColor: '#fff',
    flex: 1,
  },
  verticallySpaced: {
    paddingTop: 4,
    paddingBottom: 4,
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Mukta Bold',
    fontSize: 30,
    textAlign: 'center',
    color: '#474747',
    lineHeight: 40,
  },
  link: {
    fontFamily: 'Mukta SemiBold',
    fontSize: 18,
    marginTop: 10,
    color: '#474747',
    textAlign: 'center',
  }
})