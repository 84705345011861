import 'react-native-gesture-handler';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { HomeScreen } from './screens/home';
import { useCallback, useEffect, useState } from 'react';
import { supabase } from './lib/supabase';
import { Session } from '@supabase/supabase-js'
import LoginScreen from './screens/login';
import DataProvider from './lib/data/provider';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import { RootSiblingParent } from 'react-native-root-siblings';
import { MenuProvider } from "react-native-popup-menu";
import { createStackNavigator } from '@react-navigation/stack';
import { PatientsDetailScreen } from './screens/people/detail';
import { PatientsListScreen } from './screens/people/list-patients';
import EditPatientScreen from './screens/people/edit';
import NewPatientScreen from './screens/people/new';
import { ProfileScreen } from './screens/home/profile';
import { EditProfileScreen } from './screens/home/edit-profile';
import { PatientType, TeamType, UserType } from './lib/types/entities';
import { NotificationsScreen } from './screens/notifications';
import SignupScreen from './screens/signup';
import TerapyTypeScreen from './screens/signup/data';
import { GoalsNewScreen } from './screens/goals/new';
import ValidateScreen from './screens/signup/validate';
import { DiagnoseScreen } from './screens/goals/new-diagnose';
import NewRecordScreen from './screens/record/new';
import { GoalsEditScreen } from './screens/goals/edit';
import { ToastProvider } from 'react-native-toast-notifications'
import { TeamDetailScreen } from './screens/team';
import { TeamNewScreen } from './screens/team/new';
import { GoalsListScreen } from './screens/goals/list';
import { TeamEditScreen } from './screens/team/edit';
import { TabsNavigation } from './navigations/tab';
import { useTeamStore } from './lib/data/stores/teams';
import { usePatientStore } from './lib/data/stores/patients';
import { useTherapistStore } from './lib/data/stores/therapists';
import { StackLinkNavigation } from './navigations/stack-link';

SplashScreen.preventAutoHideAsync();
const Stack = createStackNavigator();

export default function App({ navigation }) {
  const [user, setUser] = useState<UserType | null>()
  const [session, setSession] = useState<Session | undefined | null>()

  const [dataLoaded, setDataLoaded] = useState(false)
  const [othersLoaded, setOthersLoaded] = useState(false)

  const { active, teams } = useTeamStore()

  const { setPatients } = usePatientStore.getState()
  const { setTherapists } = useTherapistStore.getState()

  const { setTeams, setActive } = useTeamStore.getState()

  useEffect(() => {
    async function prepare() {
      try {
        // Pre-load fonts, make any API calls you need to do here
        await Font.loadAsync({
          'Mukta Bold': require('./assets/fonts/Mukta-Bold.ttf'),
          'Mukta ExtraBold': require('./assets/fonts/Mukta-ExtraBold.ttf'),
          'Mukta ExtraLight': require('./assets/fonts/Mukta-ExtraLight.ttf'),
          'Mukta Light': require('./assets/fonts/Mukta-Light.ttf'),
          'Mukta Medium': require('./assets/fonts/Mukta-Medium.ttf'),
          'Mukta Regular': require('./assets/fonts/Mukta-Regular.ttf'),
          'Mukta SemiBold': require('./assets/fonts/Mukta-SemiBold.ttf')
        });
      } catch (e) {
        console.warn(e);
      } finally {
        setOthersLoaded(true);
      }
    }

    prepare()

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, []);

  useEffect(() => {
    const getData = async () => {
      const { data, error } = await supabase.functions.invoke('get-data-core', {
        headers: { Authorization: `Bearer ${session?.access_token}` },
      })

      if (!error) {
        if (data?.user) {
          if (data.user.experience !== 'link') {
            setTeams(data.user.teams)
            if (!active) {
              setActive(data.user.teams[0].id)
            }
          }
          setUser(data.user)
        }
        setDataLoaded(true)
      } else {
        console.error(error)
      }
    }
    if (session) {
      getData()
    }
  }, [session])

  useEffect(() => {
    const getData = async () => {
      const { data, error } = await supabase.functions.invoke('team', {
        headers: { Authorization: `Bearer ${session?.access_token}` },
        body: { team: active, method: 'GET' }
      })
      if (!error) {
        setPatients(data.clients.map(c => ({...c.clients})))
        setTherapists(data.users.map(c => ({...c.users})))
      }
    }
    if (active && teams.length > 0) {
      getData()
    }
  }, [teams, active])

  const setUserHandler = (value: UserType) => {
    setUser(value)
  }

  const onLayoutRootView = useCallback(async () => {
    if (dataLoaded && othersLoaded) {
      await SplashScreen.hideAsync();
    } else if (othersLoaded && session === null) {
      await SplashScreen.hideAsync();
    }
  }, [dataLoaded, othersLoaded, session]);

  if (!((dataLoaded || session === null) && othersLoaded)) {
    return null;
  }

  return (<View style={{flex: 1}}
    onLayout={onLayoutRootView}>
    <ToastProvider>
    <NavigationContainer>
      <GestureHandlerRootView style={{flex: 1}}>
        <RootSiblingParent>
          {session &&
            <DataProvider setUser={setUserHandler} session={session} user={user} tasks={[]} sessions={[]}>
              { user?.experience === 'link' ? <StackLinkNavigation /> : <TabsNavigation /> }
            </DataProvider>}
          {!session &&
            <Stack.Navigator
              id='StackOutside'
              screenOptions={{
                headerShown: false,
              }}>
              <Stack.Screen name="Login" component={LoginScreen}/>
              <Stack.Screen name="Singup" component={SignupScreen}/>
              <Stack.Screen name="Validate" component={ValidateScreen}/>
            </Stack.Navigator>
          }
        </RootSiblingParent>
      </GestureHandlerRootView>
    </NavigationContainer>
    </ToastProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
