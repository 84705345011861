
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function SvgComponent({ color = '#377F67' }) {
  return (
    <Svg width="19" height="19" viewBox="0 0 19 19" fill="none">
      <Path fillRule="evenodd" clipRule="evenodd" d="M15.0415 2.51107C14.8514 2.51107 14.6632 2.54851 14.4876 2.62125C14.312 2.694 14.1524 2.80062 14.018 2.93503L3.47914 13.4739L2.71149 16.2886L5.52622 15.521L16.0651 4.98211C16.1995 4.8477 16.3061 4.68813 16.3788 4.51251C16.4516 4.33689 16.489 4.14866 16.489 3.95857C16.489 3.76848 16.4516 3.58025 16.3788 3.40464C16.3061 3.22902 16.1995 3.06945 16.0651 2.93503C15.9306 2.80062 15.7711 2.694 15.5955 2.62125C15.4198 2.54851 15.2316 2.51107 15.0415 2.51107ZM13.8817 1.15844C14.2494 1.00613 14.6435 0.927734 15.0415 0.927734C15.4395 0.927734 15.8337 1.00613 16.2014 1.15844C16.5691 1.31076 16.9032 1.53401 17.1846 1.81545C17.4661 2.09688 17.6893 2.431 17.8417 2.79872C17.994 3.16644 18.0724 3.56056 18.0724 3.95857C18.0724 4.35659 17.994 4.7507 17.8417 5.11842C17.6893 5.48614 17.4661 5.82026 17.1846 6.1017L6.49715 16.7892C6.39973 16.8866 6.27857 16.9569 6.14566 16.9932L1.79149 18.1807C1.51741 18.2554 1.22428 18.1776 1.0234 17.9767C0.822511 17.7758 0.744667 17.4827 0.819418 17.2086L2.00692 12.8544C2.04317 12.7215 2.11348 12.6004 2.2109 12.5029L12.8984 1.81545C13.1798 1.53401 13.514 1.31076 13.8817 1.15844Z" fill={color}/>
    </Svg>
  )
}
