import { useState } from "react";
import { FormPatient, InputsPatient } from "../../components/form-patient";
import { ScrollView, View } from "react-native";
import { supabase } from "../../lib/supabase";
import useData from "../../lib/data/useData";
import { FormSession } from "../../components/form-session";

export default function NewSessionScreen({ navigation, route }) {
  const [loading, setLoading] = useState(false)
  const { userSession } = useData()

  async function onSubmitHandler(inputs: InputsPatient) {
    if (!loading) {
      setLoading(true)

      const { team, ...session } = inputs

      const { data, error } = await supabase.functions.invoke('_', {
        headers: { Authorization: `Bearer ${userSession?.access_token}` },
        body: { session, team, method: 'POST' }
      })

      if (!error) {
        {/*toast({
          title: 'Se creo el paciente correctamente',
          status: 'success'
        })*/}

        //const data = await response.json();
        //window.location.href = `/patients/${data[0].id}`
        setLoading(false)
      } else {
        {/*toast({
          title: 'Ocurrio un error, intenta nuevamente más tarde.',
          status: 'error',
        })*/}
        setLoading(false)
      }
    }
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      <FormSession onSubmit={onSubmitHandler} loading={loading}/>
    </ScrollView>
  )
}
