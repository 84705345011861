import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { CheckBox, Button, Divider } from 'react-native-elements'
import { Text, View, TextInput, StyleSheet, Pressable } from "react-native"
import DateTimePicker from '@react-native-community/datetimepicker';
import { calculateAge } from "../search/list";
import React, { useState } from "react";
import { PatientType, SessionType, TeamType } from "../../lib/types/entities";
import { ButtonComponent } from "../button";
import DropDownPicker from 'react-native-dropdown-picker';
import useData from "../../lib/data/useData";

function toJustDate(value: string | undefined) {
  const date = new Date(value || '')
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export interface InputsSession extends SessionType {
  team: TeamType
};

interface Props {
  onSubmit: SubmitHandler<InputsSession>;
  session?: SessionType;
  loading: boolean;
}

function InputComponent({control, inputKey, required = true, multiline = false, numeric = false}: {inputKey: string, control: any, required: boolean, multiline: boolean, numeric: boolean}) {
  return (<Controller
    control={control}
    rules={{
      required,
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <TextInput
        inputMode={numeric ? 'numeric' : 'text'}
        multiline={multiline}
        onBlur={onBlur}
        onChangeText={onChange}
        value={value}
        style={styles.input} />
    )}
    name={inputKey} />)
}

function DateComponent({control, open, setOpen}: {control: any, open: boolean, setOpen: any}) {
  return (<Controller
    control={control}
    rules={{
      required: true,
    }}
    render={({ field: { onChange, onBlur, value } }) => (<>
      {open && <DateTimePicker onChange={(value) => {onChange(new Date(value?.nativeEvent?.timestamp || '')); setOpen(false);}} onTouchCancel={() => setOpen(false)} display="calendar" mode="date" value={value ? new Date(value) : new Date()} maximumDate={new Date()}/>}
      {<Pressable onPress={() => setOpen(true)}><Text>{value ? toJustDate(value) : 'Seleccionar fecha'}</Text></Pressable>}
    </>)}
    name={'date'} />)
}

function DropdownComponent({control, open, types, setOpen, setTypes}: {control: any}) {
  return (<Controller
    control={control}
    rules={{
      required: true,
    }}
    render={({ field: { onChange, onBlur, value } }) => (
    <DropDownPicker
      open={open}
      value={value.value}
      items={types}
      setOpen={setOpen}
      setValue={onChange}
      setItems={setTypes}
      searchable={false}
      placeholder="Equipo"
      searchPlaceholder="Buscar"
      translation={{
        NOTHING_TO_SHOW: 'No hay resultados'
      }}
      dropDownContainerStyle={styles.dropdown}
      placeholderStyle={styles.selectPlaceholder}
      searchTextInputStyle={styles.searchInput}
    />)}
    name={'team'} />
  )
}

const inputsData = {
  title: {
    label: 'Titulo'
  }
}

export function FormSession({ onSubmit, session, loading }: Props) {
  const { user } = useData()
  const teams = user.teams.map(t => ({ value: t.id, label: t.name }))
  const { control, handleSubmit, watch, formState: { errors } } = useForm<InputsSession>({
    defaultValues: {
      date: session?.date,
      duration: session?.duration,
      repeat: session?.repeat,
      tags: session?.tags,
      title: session?.title,
    }
  });

  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState(teams);

  const dropdownProps = {
    open,
    setOpen,
    types,
    setTypes
  }

  const inputsGenerator = (data) => Object.keys(data).map((input) => {
    const Component = data[input].Component
    return (<View key={input} style={data[input]?.properties?.multiline ? styles.containerWideInput : styles.containerInput}>
      <Text style={styles.label}>{data[input].label}</Text>
      {Component && <Component {...data[input].properties} />}
      {!Component && <InputComponent inputKey={input} control={control} {...data[input].properties}/>}
      {errors[input] && <Text>Este campo es obligatorio</Text>}
    </View>)
  })

  const inputs = inputsGenerator(inputsData)

  return (
    <View style={styles.container}>
      <View style={styles.containerInputs}>
        {inputs}

        <View style={styles.containerInput}>
          <Text style={styles.label}>Fecha</Text>
          <DateComponent control={control} open={open} setOpen={setOpen}/>
        </View>
      </View>

      <View style={{ marginTop: 150 }}>
        <ButtonComponent text="Guardar" loading={loading} onPress={handleSubmit(onSubmit)}/>
      </View>
    </View>
    )
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      borderRadius: 5,
      margin: 18,
      padding: 18,
      backgroundColor: '#fff',
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#474747',
      marginVertical: 10,
    },
    label: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#474747',
      marginVertical: 10,
    },
    containerInputs: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      flex: 1,
    },
    containerInput: {
      width: '50%',
      padding: 5,
    },
    containerWideInput: {
      width: '100%',
    },
    input: {
      padding: 10,
      borderStyle: 'solid',
      borderColor: '#DADADA',
      borderWidth: 1,
      borderRadius: 4,
    },
    searchInput: {
      fontFamily: 'Mukta Regular',
      padding: 10,
      borderStyle: 'solid',
      borderColor: '#DADADA',
      fontSize: 18,
    },
    selectPlaceholder: {
      fontFamily: 'Mukta Regular',
      fontSize: 18,
    },
    dropdown: {
      borderRadius: 4,
      borderColor: '#DADADA',
    }
  })
