import { create } from 'zustand'
import { TeamType } from '../../types/entities'

interface TeamsState {
  teams: TeamType[];
  active: string;
  setActive: (id: string) => void;
  getActive: () => TeamType | null;
  setTeams: (teams: TeamType[]) => void;
}

export const useTeamStore = create<TeamsState>()((set, get) => ({
  teams: [],
  setTeams: (teams) => set(() => ({ teams })),
  active: '',
  setActive: (id) => set(() => ({ active: id })),
  getActive: () => get().teams.find(t => t.id === get().active) || null,
}))