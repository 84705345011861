import { StyleSheet } from "react-native";
import { Avatar, Text, View } from "react-native-ui-lib";
import { UserType } from "../../lib/types/entities";

interface Props extends UserType {
  big: boolean;
}

export function PatientRow({ image, first_name, last_name, big }: Props) {
  return (
    <View
      paddingH-10
      paddingV-5
      row
      flex
      centerV
    >
    <Avatar source={{uri: image}} size={big ? 40 : 20}/>
    <View marginL-5>
      <Text text90={!big} text70={big}>
        {!big && `${first_name} ${last_name?.charAt(0)}.`}
        {big && `${first_name} ${last_name}`}
      </Text>
    </View>
  </View>
  )
}

const styles = StyleSheet.create({
});