import { useEffect, useState } from "react"
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import { TextInput } from 'react-native'
import { supabase } from "../../lib/supabase"
import { ButtonComponent } from "../../components/button"
import { useToast } from "react-native-toast-notifications";
import * as Linking from 'expo-linking';

export default function LoginScreen({ navigation, route }) {
  const [email, setEmail] = useState(route.params?.email || '')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast();
  const url = Linking.useURL();

  useEffect(() => {
    const executeSetSession = async (token, refresh) => {
      const { data: dataAuth, error: errorAuth } = await supabase.auth.setSession({
        access_token: token,
        refresh_token: refresh
      });
      console.log(dataAuth, errorAuth)
      if (!errorAuth) Linking.openURL('/')
    }

    const hasInvitation = url?.includes('?')
    const finalURl = hasInvitation ? url?.replace('#', '&') : url?.replace('#', '?')
    if (finalURl) {
      const { queryParams } = Linking.parse(finalURl);
      const token = queryParams?.['access_token']
      const refresh = queryParams?.['refresh_token']
      if (token && refresh) {
        executeSetSession(token, refresh)
      }
    }
  }, [url])

  async function signInGoogle() {
    setLoading(true)
    let { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.href
      }
    })
    console.log(data, error)

    if (error) {
      let message = 'Ocurrio un error, intenta nuevamente más tarde.'

      toast.show(message, {
        type: 'danger',
        duration: 5000
      });
    }
    setLoading(false)
  }

  async function signInWithEmail() {
    setLoading(true)
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    })

    if (error) {
      let message = 'Ocurrio un error, intenta nuevamente más tarde.'
      if (error.message === 'Invalid login credentials') {
        message = 'Los datos sin incorrectos'
      } else if (error.message === 'Email not confirmed') {
        message = 'Revisa tu correo para validar tu cuenta'
      }

      toast.show(message, {
        type: 'danger',
        duration: 5000
      });
    }
    setLoading(false)
  }

  const disabled = email === '' || password === '' || loading

  return (
    <View style={styles.container}>
      <Image source={require('../../assets/ilus_1.png')} style={{ alignSelf: 'center', height: 246 - 20, width: 226 - 20 }} resizeMode='contain'/>
      {/*<Text style={styles.title}>Ingresar</Text>*/}

      {/*<View>
        <ButtonComponent text="Ingresar con Google" loading={false} onPress={signInGoogle} />
  </View>*/}

      <View style={[styles.verticallySpaced]}>
        <TextInput
          onChangeText={(text) => setEmail(text)}
          value={email}
          placeholder="Email"
          autoCapitalize={'none'}
          style={styles.input}
        />
      </View>
      <View style={styles.verticallySpaced}>
        <TextInput
          onChangeText={(text) => setPassword(text)}
          value={password}
          secureTextEntry={true}
          placeholder="Contraseña"
          autoCapitalize={'none'}
          style={styles.input}
        />
      </View>
      <ButtonComponent text="Ingresar" loading={disabled} onPress={signInWithEmail}/>
      <Pressable onPress={() => { navigation.navigate("Singup") }}>
        <Text style={styles.link}>¿No tenés cuenta?</Text>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 50,
    backgroundColor: '#fff',
    flex: 1,
  },
  verticallySpaced: {
    paddingTop: 4,
    paddingBottom: 4,
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Mukta Bold',
    fontSize: 30,
    textAlign: 'center',
    color: '#474747'
  },
  input: {
    fontFamily: 'Mukta Regular',
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    borderWidth: 1,
    fontSize: 18,
    borderRadius: 4,
    width: 250,
  },
  link: {
    fontFamily: 'Mukta SemiBold',
    fontSize: 18,
    marginTop: 10,
    color: '#474747',
    textAlign: 'center',
  }
})