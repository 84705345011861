import { View } from "react-native"
import useData from "../../lib/data/useData"
import { useNavigation } from "@react-navigation/native";
import {
  Avatar,
  Chip,
  Picker,
  Text
} from 'react-native-ui-lib';
import { TeamType } from "../../lib/types/entities";
import { useTeamStore } from "../../lib/data/stores/teams";
const chevron = require('../../assets/chevron.png') 

export function HeaderComponent({}) {
  const { user } = useData()
  const { getActive, setActive, teams } = useTeamStore()
  const navigation = useNavigation()

  const activeTeam = getActive()
  const teamsComponent = teams?.map((t: TeamType) => (<Picker.Item key={t.id} label={t.name || ''} value={t.id}/>))
  teamsComponent.push(<Picker.Item key='9999' label='Crear nuevo equipo' value='9999'/>)

  return (
    <View style={{ padding: 20, backgroundColor: '#fff', flexDirection: 'row', gap: 20, justifyContent: 'flex-end', alignItems: 'center' }}>
      <Picker
        value={activeTeam?.id}
        onChange={(id: string) => { id !== '9999' ? setActive(id) : navigation.navigate('NewTeam')}}
        renderPicker={(_value?: any, label?: string) => {
          return (<Chip label={activeTeam?.name || ''} rightIconSource={chevron}
          iconStyle={{margin: 8}} onPress={() => console.log('pressed')}/>);
        }}
      >
        {teamsComponent}
      </Picker>
      <Avatar source={{uri: user?.image || ''}} label={`${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`}/>

    </View>
  )
}