import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { CheckBox, Button, Divider } from 'react-native-elements'
import { Text, View, TextInput, StyleSheet, Pressable } from "react-native"
import DateTimePicker from '@react-native-community/datetimepicker';
import { calculateAge } from "../search/list";
import React, { useState } from "react";
import { PatientType, TeamType } from "../../lib/types/entities";
import { ButtonComponent } from "../button";
import DropDownPicker from 'react-native-dropdown-picker';
import useData from "../../lib/data/useData";
import { useTeamStore } from "../../lib/data/stores/teams";

function toJustDate(value: string | undefined) {
  const date = new Date(value || '')
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export interface InputsPatient extends PatientType {
  team: string
};

interface Props {
  onSubmit: SubmitHandler<InputsPatient>;
  patient?: PatientType;
  loading: boolean;
}

function InputComponent({control, inputKey, required = true, multiline = false, numeric = false}: {inputKey: string, control: any, required: boolean, multiline: boolean, numeric: boolean}) {
  return (<Controller
    control={control}
    rules={{
      required,
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <TextInput
        inputMode={numeric ? 'numeric' : 'text'}
        multiline={multiline}
        onBlur={onBlur}
        onChangeText={onChange}
        value={value}
        style={styles.input} />
    )}
    name={inputKey} />)
}

function DateComponent({control, open, setOpen}: {control: any, open: boolean, setOpen: any}) {
  return (<Controller
    control={control}
    rules={{
      required: true,
    }}
    render={({ field: { onChange, onBlur, value } }) => (<>
      {open && <DateTimePicker onChange={(value) => {onChange(new Date(value?.nativeEvent?.timestamp || '')); setOpen(false);}} onTouchCancel={() => setOpen(false)} display="calendar" mode="date" value={value ? new Date(value) : new Date()} maximumDate={new Date()}/>}
      {<Pressable onPress={() => setOpen(true)}><Text>{value ? toJustDate(value) : 'Seleccionar fecha'}</Text></Pressable>}
    </>)}
    name={'birthdate'} />)
}

function DropdownComponent({control, open, types, setOpen, setTypes, disabled}: {control: any}) {
  return (<Controller
    control={control}
    rules={{
      required: true,
    }}
    render={({ field: { onChange, onBlur, value } }) => (
    <DropDownPicker
      open={open}
      value={value}
      items={types}
      setOpen={setOpen}
      setValue={onChange}
      setItems={setTypes}
      searchable={false}
      disabled={disabled}
      placeholder="Equipo"
      searchPlaceholder="Buscar"
      translation={{
        NOTHING_TO_SHOW: 'No hay resultados'
      }}
      dropDownContainerStyle={styles.dropdown}
      placeholderStyle={styles.selectPlaceholder}
      searchTextInputStyle={styles.searchInput}
    />)}
    name={'team'} />
  )
}

const inputsData = {
  first_name: {
    label: 'Nombre'
  },
  last_name: {
    label: 'Apellido'
  },
  /*age:{
    label: 'Años',
    Component: ({watch}) => <Text>{watch('birthdate') && calculateAge(watch('birthdate') || '')}</Text>
  },*/
  /*identification: {
    label: 'DNI',
    properties: {
      numeric: true
    }
  },
  address: {
    label: 'Dirección'
  },
  genre: {
    label: 'Género'
  },
  sex: {
    label: 'Sexo'
  }*/
}
const inputsDataContact = {
  /*email: {
    label: 'Email'
  },
  phone: {
    label: 'Teléfono',
    properties: {
      numeric: true
    }
  }*/
}
const inputsDataUrgency = {
  /*urgent_name: {
    label: 'Nombre',
    properties: {
      required: false
    }
  },
  urgent_contact: {
    label: 'Teléfono',
    properties: {
      required: false,
      numeric: true
    }
  }*/
}
const inputsDataFamily = {
  /*family: {
    label: 'Composición familiar',
    properties: {
      multiline: true,
      required: false
    }
  }*/
}
const inputsDataOS = {
  /*particular: {
    label: 'Consulta particular',
    properties: {
      required: false
    }
  },
  os_name: {
    label: 'Nombre',
    properties: {
      required: false
    }
  },
  os_plan: {
    label: 'Plan',
    properties: {
      required: false
    }
  },
  os_user_identification: {
    label: 'Identificación',
    properties: {
      required: false
    }
  }*/
}
const inputsDataOther = {
  initial_diagnose: {
    label: 'Diagnóstico de Ingreso',
    properties: {
      multiline: true,
      required: false
    }
  },
  initial_reason: {
    label: 'Motivo de consulta',
    properties: {
      multiline: true,
      required: false
    }
  }
}

export function FormPatient({ onSubmit, patient, loading }: Props) {
  const { user } = useData()
  const { getActive, teams } = useTeamStore()
  const _teams = teams.map(t => ({ value: t.id, label: t.name }))
  const { control, handleSubmit, watch, formState: { errors } } = useForm<InputsPatient>({
    defaultValues: {
      id: patient?.id,
      first_name: patient?.first_name,
      last_name: patient?.last_name,
      team: _teams.find(t => (patient?.team ? (t.value === patient?.team) : (t.value === getActive()?.id)))?.value,
      birthdate: patient?.birthdate,
      identification: patient?.identification,
      address: patient?.address,
      genre: patient?.genre,
      sex: patient?.sex,
      urgent_name: patient?.urgent_name,
      urgent_contact: patient?.urgent_contact,
      family: patient?.family,
      particular: patient?.particular,
      os_name: patient?.os_name,
      os_plan: patient?.os_plan,
      os_user_identification: patient?.os_user_identification,
      email: patient?.email,
      phone: patient?.phone,
      initial_diagnose: patient?.initial_diagnose,
      initial_reason: patient?.initial_reason
    }
  });

  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState(_teams);

  const dropdownProps = {
    open,
    setOpen,
    types,
    setTypes,
    disabled: !!patient
  }

  const inputsGenerator = (data) => Object.keys(data).map((input) => {
    const Component = data[input].Component
    return (<View key={input} style={data[input]?.properties?.multiline ? styles.containerWideInput : styles.containerInput}>
      <Text style={styles.label}>{data[input].label}</Text>
      {Component && <Component {...data[input].properties} />}
      {!Component && <InputComponent inputKey={input} control={control} {...data[input].properties}/>}
      {errors[input] && <Text>Este campo es obligatorio</Text>}
    </View>)
  })

  const inputs = inputsGenerator(inputsData)
  const inputsContact = inputsGenerator(inputsDataContact)
  const inputsUrgency = inputsGenerator(inputsDataUrgency)
  const inputsFamily = inputsGenerator(inputsDataFamily)
  const inputsOS = inputsGenerator(inputsDataOS)
  const inputsOther = inputsGenerator(inputsDataOther)

  return (
    <View style={styles.container}>
      <View style={styles.containerInputs}>
        {inputs}

        {/*<View style={styles.containerInput}>
          <Text style={styles.label}>F. de nacimiento</Text>
          <DateComponent control={control} open={open} setOpen={setOpen}/>
        </View>*/}
        <DropdownComponent control={control} {...dropdownProps}/>
      </View>

      {/*<Divider style={{ marginVertical: 20 }} />
      <Text style={styles.title}>Contacto</Text>
      <View style={styles.containerInputs}>
        {inputsContact}
      </View>

      <Divider style={{ marginVertical: 20 }} />
      <Text style={styles.title}>Contacto de urgencia</Text>
      <View style={styles.containerInputs}>
        {inputsUrgency}
      </View>

      <Divider style={{ marginVertical: 20 }} />
      <Text style={styles.title}>Obra social</Text>
      <View style={styles.containerInputs}>
        {inputsOS}
      </View>

      <Divider style={{ marginVertical: 20 }} />
      <Text style={styles.title}>Familiares</Text>
      {inputsFamily}*/}

     {/*} <Divider style={{ marginVertical: 20 }} />
      <Text style={styles.title}>Datos iniciales</Text>
    {inputsOther}*/}

      {/*<Text>Institución Académica</Text>*/}
      <View style={{ marginTop: 150 }}>
        <ButtonComponent text="Guardar" loading={loading} onPress={handleSubmit(onSubmit)}/>
      </View>
    </View>
    )
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      borderRadius: 5,
      margin: 18,
      padding: 18,
      backgroundColor: '#fff',
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#474747',
      marginVertical: 10,
    },
    label: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#474747',
      marginVertical: 10,
    },
    containerInputs: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      flex: 1,
    },
    containerInput: {
      width: '50%',
      padding: 5,
    },
    containerWideInput: {
      width: '100%',
    },
    input: {
      padding: 10,
      borderStyle: 'solid',
      borderColor: '#DADADA',
      borderWidth: 1,
      borderRadius: 4,
    },
    searchInput: {
      fontFamily: 'Mukta Regular',
      padding: 10,
      borderStyle: 'solid',
      borderColor: '#DADADA',
      fontSize: 18,
    },
    selectPlaceholder: {
      fontFamily: 'Mukta Regular',
      fontSize: 18,
    },
    dropdown: {
      borderRadius: 4,
      borderColor: '#DADADA',
    }
  })
