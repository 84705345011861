import { createStackNavigator } from '@react-navigation/stack';
import React from 'react'
import NewPatientScreen from './new';
import { CalendarHomeScreen } from './home';
import NewSessionScreen from './new';

const Stack = createStackNavigator();

export function CalendarNavScreen() {
  return (
    <Stack.Navigator
      id='StackCalendar'
      screenOptions={{
        headerShadowVisible: false,
        headerShown: false,
        title: '',
      }}>
      <Stack.Screen name="CalendarHome" component={CalendarHomeScreen}/>
      <Stack.Screen name="NewSession" component={NewSessionScreen} options={{ title: 'Nueva sesión' }}/>
    </Stack.Navigator>
  )
}