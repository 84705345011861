import { useEffect, useState } from "react"
import { Image, StyleSheet, Text, View } from 'react-native'
import { TextInput } from 'react-native'
import { supabase } from "../../lib/supabase"
import { useToast } from "react-native-toast-notifications";
import { ButtonComponent } from "../../components/button"
import DropDownPicker from 'react-native-dropdown-picker';
import useData from "../../lib/data/useData"

export default function TerapyTypeScreen({ navigation }) {
  const { user, userSession, setUser } = useData()
  const [name, setName] = useState(user?.first_name || '')
  const [lastName, setLastname] = useState(user?.last_name || '')
  const [terapy, setTerapy] = useState(user?.therapy_type || '')
  const [avatar, setAvatar] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast();

  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState([
    {label: 'Psicología', value: 'psico'},
  ]);

  useEffect(() => {
    const getDataFromGoogle = async () => {
      const user = await supabase.auth.getUser()
      const fullName = user?.data?.user?.user_metadata?.full_name
      const avatar = user?.data?.user?.user_metadata?.avatar_url
      const names = fullName?.split(' ')
      if (avatar) {
        setAvatar(avatar)
      }
      if (names) {
        setName(names?.[0])
        if (names.length > 2) {
          setLastname(names?.[2])
        } else {
          setLastname(names?.[1])
        }
      }
    }
    //getDataFromGoogle()
  }, [])

  async function saveData() {
    setLoading(true)
    let functionToInvoke = 'save-data-user'

    if (!user) {
      functionToInvoke = 'new-data-user'
    }

    const { data, error } = await supabase.functions.invoke(functionToInvoke, {
      headers: { Authorization: `Bearer ${userSession?.access_token}` },
      body: { user: { first_name: name, last_name: lastName, therapy_type: terapy, image: avatar }}
    })

    if (error) {
      const message = 'Ocurrio un error, intenta nuevamente más tarde.'
      toast.show(message, {
        type: 'danger',
        duration: 5000
      });
    } else {
      setUser(data.user)
      setTimeout(() => {
        navigation.replace("Home")
      }, 1000)
    }
    setLoading(false)
  }

  const disabled = name === '' || lastName === '' || loading || terapy === ''

  return (
    <View style={styles.container}>
      <Image source={require('../../assets/ilus_3.png')} style={{ alignSelf: 'center', height: 246 - 20, width: 226 - 20 }} resizeMode='contain'/>
      <Text style={styles.title}>Tus datos</Text>

      <View style={styles.name}>
        <TextInput
          onChangeText={(text) => setName(text)}
          value={name}
          placeholder="Nombre"
          autoCapitalize={'none'}
          style={styles.input}
        />
        <TextInput
          onChangeText={(text) => setLastname(text)}
          value={lastName}
          placeholder="Apellido"
          autoCapitalize={'none'}
          style={styles.input}
        />
      </View>
        <DropDownPicker
          open={open}
          value={terapy}
          items={types}
          setOpen={setOpen}
          setValue={setTerapy}
          setItems={setTypes}
          searchable={true}
          placeholder="Tipo de terapia"
          searchPlaceholder="Buscar"
          translation={{
            NOTHING_TO_SHOW: 'No hay resultados'
          }}
          dropDownContainerStyle={styles.dropdown}
          placeholderStyle={styles.selectPlaceholder}
          searchTextInputStyle={styles.searchInput}
        />
      <ButtonComponent text="Continuar" loading={disabled} onPress={saveData}/>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 12,
    backgroundColor: '#fff',
    flex: 1,
  },
  name: {
    paddingTop: 4,
    paddingBottom: 4,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10
  },
  title: {
    fontFamily: 'Mukta Bold',
    fontSize: 30,
    textAlign: 'center',
    color: '#474747'
  },
  input: {
    fontFamily: 'Mukta Regular',
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    borderWidth: 1,
    fontSize: 18,
    borderRadius: 4,
    flex: 1,
    width: '50%'
  },
  link: {
    fontFamily: 'Mukta semiBold',
    fontSize: 18,
    color: '#474747',
    textAlign: 'center',
  },
  searchInput: {
    fontFamily: 'Mukta Regular',
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    fontSize: 18,
  },
  selectPlaceholder: {
    fontFamily: 'Mukta Regular',
    fontSize: 18,
  },
  dropdown: {
    borderRadius: 4,
    borderColor: '#DADADA',
  }
})