import { View, FlatList, Text, StyleSheet, Pressable } from "react-native"
import { IPatient } from '.'
import { PatientType } from "../../lib/types/entities";
import { PatientRow } from "../patient-row";

interface Props {
  items: IPatient[];
  full: boolean;
  onSelectPatient: (patient: PatientType) => void;
}

export default function ListPatients({ items, onSelectPatient }: Props) {
  return (
    <View style={styles.container}>
      <FlatList
        style={styles.list}
        data={items}
        renderItem={({item}) => (
          <Pressable style={styles.itemContainer} onPress={() => onSelectPatient(item)}>
            <PatientRow {...item} big/>
          </Pressable>
        )}
        keyExtractor={(item) => item?.id?.toString()}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 2,
  },
  list: {
    flex: 1,
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 80,
  },
  itemContainer: {

  }
})