import { create } from 'zustand'
import { UserType } from '../../types/entities'

interface TherapistsState {
  therapists: UserType[];
  setTherapists: (therapists: UserType[]) => void;
}

export const useTherapistStore = create<TherapistsState>()((set, get) => ({
  therapists: [],
  setTherapists: (therapists) => set(() => ({ therapists })),
}))