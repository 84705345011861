import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { CheckBox, Button, Divider } from 'react-native-elements'
import { Text, View, TextInput, StyleSheet, Pressable } from "react-native"
import DateTimePicker from '@react-native-community/datetimepicker';
import React, { useEffect, useState } from "react";
import { GoalType, PatientType } from "../../lib/types/entities";
import { ButtonComponent } from "../button";
import DropDownPicker from 'react-native-dropdown-picker';
import Plus from '../../lib/icons/plus'
import Slider from '@react-native-community/slider';

export interface InputsGoal extends GoalType { };

interface Props {
  onSubmit: SubmitHandler<InputsGoal>;
  patient?: PatientType;
  goal?: GoalType;
  loading: boolean;
}

function InputComponent({ control, inputKey, required = true, multiline = false, numeric = false }: { inputKey: string, control: any, required: boolean, multiline: boolean, numeric: boolean }) {
  return (<Controller
    control={control}
    rules={{
      required,
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <TextInput
        inputMode={numeric ? 'numeric' : 'text'}
        multiline={multiline}
        onBlur={onBlur}
        onChangeText={onChange}
        value={value}
        style={styles.input} />
    )}
    name={inputKey} />)
}

function SlideComponent({ control, inputKey, required = true, multiline = false, numeric = false }: { inputKey: string, control: any, required: boolean, multiline: boolean, numeric: boolean }) {
  return (<Controller
    control={control}
    rules={{
      required,
    }}
    render={({ field: { onChange, onBlur, value } }) => (<View style={styles.slider}>
      <Slider
        style={{width: 300, height: 40}}
        minimumValue={0}
        maximumValue={100}
        value={value}
        onValueChange={(value) => onChange(value.toFixed(0))}
      />
      <Text>{value ? parseInt(value) : '0'}%</Text>
   </View>)}
    name={inputKey} />)
}

const inputsDataBase = {
  goal_text: {
    label: 'Objetivo principal',
    properties: {
      multiline: true
    }
  },
  goal_track: {
    label: 'Seguimiento',
    Component: SlideComponent,
    properties: {
      required: false
    }
  }
}

const inputsPreDataBase = {
  key_1_text: {
    properties: {
      multiline: true
    }
  },
  key_1_track: {
    Component: SlideComponent,
    properties: {
      required: false
    }
  },
}

const getCountKeys = (prevState) => {
  return Object.keys(prevState)?.filter(key => key.includes('key')).filter(key => key.includes('text')).length
}

const getKeys = (goal: GoalType) => {
  return goal && Object.keys(goal).filter(key => key.includes('key')).filter(key => goal[key]).filter(key => key.includes('text'))
}

const getNewKey = (count: number) => {
  return ({
    [`key_${count}_text`]: {
      properties: {
        multiline: true,
        required: false
      }
    },
    [`key_${count}_track`]: {
      Component: SlideComponent,
      properties: {
        required: false
      }
    },
  })
}

export function FormGoal({ onSubmit, patient, goal, loading }: Props) {
  const [inputsPreData, setInputsPreData] = useState(inputsPreDataBase)
  const { control, handleSubmit, watch, formState: { errors } } = useForm<InputsGoal>({
    defaultValues: {
      goal_text: goal?.goal_text,
      key_1_text: goal?.key_1_text,
      key_2_text: goal?.key_2_text,
      key_3_text: goal?.key_3_text,
      key_4_text: goal?.key_4_text,
      key_5_text: goal?.key_5_text,
      goal_track: goal?.goal_track,
      key_1_track: goal?.key_1_track,
      key_2_track: goal?.key_2_track,
      key_3_track: goal?.key_3_track,
      key_4_track: goal?.key_4_track,
      key_5_track: goal?.key_5_track,
    }
  });

  useEffect(() => {
    const keys = getKeys(goal)
    if (keys) {
      setInputsPreData(prevState => {
        let finalMap = new Map()
        keys.forEach((key, index) => {
          finalMap.set(key, {
            properties: {
              multiline: true,
              required: false
            }
          })
          finalMap.set(`key_${index + 1}_track`, {
            Component: SlideComponent,
            properties: {
              required: false
            }
          })
        })

        return ({...prevState, ...Object.fromEntries(finalMap.entries())})
      })
    }
  }, [])

  useEffect(() => {
    console.log(inputsPreData)
  }, [inputsPreData])

  const addKeys = () => {
    setInputsPreData(prevState => {
      const count = getCountKeys(prevState)
      const newKey = getNewKey(count + 1)
      return ({...prevState, ...newKey})
    })
  }

  const [open, setOpen] = useState(false);

  const inputsGenerator = (data) => Object.keys(data).map((input) => {
    const Component = data[input].Component
    return (<View key={input} style={data[input]?.properties?.multiline ? styles.containerWideInput : styles.containerInput}>
      <Text style={styles.label}>{data[input].label}</Text>
      {Component && <Component inputKey={input} control={control} {...data[input].properties} />}
      {!Component && <InputComponent inputKey={input} control={control} {...data[input].properties} />}
      {errors[input] && <Text>Este campo es obligatorio</Text>}
    </View>)
  })

  const inputs = inputsGenerator(inputsDataBase)
  const inputsPre = inputsGenerator(inputsPreData)

  return (
    <View style={styles.container}>
      <View style={styles.containerInputs}>
        {inputs}
      </View>

      <Divider style={styles.divider}/>

      <View style={styles.columns}>
        <Text style={styles.label}>Prerrequisitos</Text>
        <Pressable onPress={addKeys}>
          <Text style={styles.addText}>Añadir</Text>
        </Pressable>
      </View>
      <View style={styles.containerInputs}>
        {inputsPre}
      </View>

      <ButtonComponent text="Guardar" loading={loading} onPress={handleSubmit(onSubmit)} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 5,
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#474747',
    marginVertical: 10,
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#474747',
    marginVertical: 10,
  },
  containerInputs: {
   
  },
  input: {
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    borderWidth: 1,
    borderRadius: 4,
  },
  slider: {
    flexDirection: 'row',
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  columns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addText: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: '#377F67',
  },
  divider: {
    marginTop: 15,
    marginBottom: 10
  }
})