import { Pressable, StyleSheet, Text, View } from "react-native";
import { GoalType } from "../../lib/types/entities";
import { Divider } from "react-native-elements";
import * as Progress from 'react-native-progress'
import Pencil from '../../lib/icons/pencil'
import Chevron from '../../lib/icons/chevron'
import { useState } from "react";

interface Props {
  goal: GoalType;
  onEdit: () => void;
}

export function Goal({ goal, onEdit }: Props) {
  const [open, setOpen] = useState<string>();

  const isOpen = (id: string) => {
    return open === id
  }

  return (
    <View style={styles.container} key={goal.created_at?.toString()}>
      <View style={styles.header}>
        <Text style={styles.title}>{goal.goal_text}</Text>
        {isOpen(goal.id) && <Pressable onPress={onEdit}>
          <Pencil />
        </Pressable>}
        {!isOpen(goal.id) && <Pressable onPress={() => setOpen(goal.id)}>
          <Chevron />
        </Pressable>}
      </View>
      <View style={styles.trackGoal}>
        <Progress.Bar progress={(goal.goal_track || 0) / 100} width={80} height={6} style={styles.progressGoal} />
        <Text style={styles.subtitle}>{goal.goal_track || 0}%</Text>
      </View>

      <Divider style={styles.divider} />

      <Text style={styles.subtitle}>Prerrequisitos</Text>
      {isOpen(goal.id) && <View style={styles.columnsGoals}>
        <View style={styles.columnGoals}>
          <Text style={styles.text}>{goal.key_1_text}</Text>
          {goal.key_2_text && <Text style={styles.text}>{goal.key_2_text}</Text>}
          {goal.key_3_text && <Text style={styles.text}>{goal.key_3_text}</Text>}
          {goal.key_4_text && <Text style={styles.text}>{goal.key_4_text}</Text>}
          {goal.key_5_text && <Text style={styles.text}>{goal.key_5_text}</Text>}
        </View>
        <View style={styles.columnGoalsProgres}>
          {goal.key_1_text && <View style={styles.trackGoal}>
            <Progress.Bar progress={(goal.key_1_track || 0) / 100} width={60} height={6} style={styles.progressGoal} />
            <Text style={styles.text}>{goal.key_1_track || 0}%</Text>
          </View>}
          {goal.key_2_text && <View style={styles.trackGoal}>
            <Progress.Bar progress={(goal.key_2_track || 0) / 100} width={60} height={6} style={styles.progressGoal} />
            <Text style={styles.text}>{goal.key_2_track || 0}%</Text>
          </View>}
          {goal.key_3_text && <View style={styles.trackGoal}>
            <Progress.Bar progress={(goal.key_3_track || 0) / 100} width={60} height={6} style={styles.progressGoal} />
            <Text style={styles.text}>{goal.key_3_track || 0}%</Text>
          </View>}
        </View>
      </View>}
    </View>)
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#ccc',
    padding: 10,
    marginVertical: 5,
    
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    fontFamily: 'Mukta Bold',
    fontSize: 16
  },
  subtitle: {
  },
  text: {
    fontFamily: 'Mukta Regular',
    width: 30
  },
  columnsGoals: {
    flexDirection: 'row'
  },
  columnGoals: {
    width: '50%'
  },
  columnGoalsProgres: {
    width: '50%'
  },
  trackGoal: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 5
  },
  progressGoal: {
    alignSelf: 'center'
  },
  divider: {
    marginTop: 15,
    marginBottom: 10
  }
})