import React, { useCallback, useEffect, useState } from 'react'
import { FlatList, StyleSheet, Text, View } from "react-native"
import useData from '../../lib/data/useData'
import { UserType } from '../../lib/types/entities'
import { supabase } from '../../lib/supabase'
import { useFocusEffect } from '@react-navigation/native'
import { Divider } from 'react-native-elements'
import { Card } from 'react-native-ui-lib'
import { TherapistRow } from '../../components/therapist-row'
import { PatientRow } from '../../components/patient-row'
import { useTeamStore } from '../../lib/data/stores/teams'
import { usePatientStore } from '../../lib/data/stores/patients'
import { useTherapistStore } from '../../lib/data/stores/therapists'
import { HeaderComponent } from '../../components/header'

export function PeopleHomeScreen({navigation, route}) {
  const { patients } = usePatientStore()
  const { therapists } = useTherapistStore()
  const { user } = useData()

  useEffect(() => {
    if (!user?.therapy_type) {
      navigation.replace('TerapyType')
    }
  }, [user])

  return <View style={styles.container}>
    <HeaderComponent />
    
    <View style={{ gap: 10, flexDirection: 'row', paddingVertical: 10 }}>
      <Card flex center onPress={() => navigation.navigate("ListTherapists")}>
        <Text style={{ paddingVertical: 5 }}>Terapeutas</Text>
        <Divider />
        <FlatList
          style={{ width: "100%" }}
          data={therapists}
          renderItem={({ item }) => <TherapistRow {...item} />}
          keyExtractor={(item) => item?.id}
        />
      </Card>
      <Card flex center onPress={() => navigation.navigate("ListPatients")}>
        <Text style={{ paddingVertical: 5 }}>Pacientes</Text>
        <Divider />
        <FlatList
          style={{ width: "100%" }}
          data={patients}
          renderItem={({ item }) => <PatientRow {...item} />}
          keyExtractor={(item) => item?.id}
        />
      </Card>
    </View>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    padding: 20,
  },
  therapistsTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  inviteText: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  inviteTextDisabled: {
    backgroundColor: '#999',
  },
  inviteTextEnabled: {
    backgroundColor: '#377F67',
  },
  title: {
    fontFamily: 'Mukta Bold',
    fontSize: 18
  },
  divider: {
    marginTop: 15,
    marginBottom: 10
  },
  input: {
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    borderWidth: 1,
    borderRadius: 4,
    flex: 2
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    paddingTop: 5
  },
})