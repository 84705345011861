import { StyleSheet, View } from "react-native";
import { Button } from "react-native-elements";

export function ButtonComponent({ text, small = false, loading, onPress }: { small?: boolean, text: string, loading: boolean, onPress: () => void}) {
  return (
    <View style={styles.container}>
      <Button buttonStyle={[styles.button, small ? styles.small : styles.regular]} titleStyle={[small ? styles.smallText : styles.regularText]} title={text} disabled={loading} onPress={onPress} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 4,
    paddingBottom: 4,
    alignItems: 'center',
  },
  button: {
    fontFamily: 'Mukta Regular',
    backgroundColor: '#377F67',
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
  },
  small: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    width: 100,
  },
  smallText: {
    fontSize: 14
  },
  regular: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    width: 150,
  },
  regularText: {
    fontSize: 18
  }
})

