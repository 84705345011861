import React from 'react'
import { Pressable, StyleSheet, Text, View } from "react-native"
import Search from '../../components/search'
import { PatientType } from '../../lib/types/entities'
import { useTeamStore } from '../../lib/data/stores/teams'
import { usePatientStore } from '../../lib/data/stores/patients'

export function PatientsListScreen({navigation, route}) {
  const { patients } = usePatientStore()

  const { active } = useTeamStore.getState()

  const onSelectPatient = (patient: PatientType) => {
    navigation.push('DetailPatient', { patient });
  }

  return <View style={styles.container}>
    <View>
      <View style={styles.therapistsTitle}>
        <Text style={styles.title}>Pacientes</Text>
        <Pressable onPress={() => { navigation.navigate("NewPatient", { team: active }) }}>
          <Text style={[styles.inviteText, styles.inviteTextEnabled]}>Agregar</Text>
        </Pressable>
      </View>
    </View>
    <Search patients={patients} onSelectPatient={onSelectPatient} full/>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: 20,
    paddingTop: 40,
  },
  therapistsTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  inviteText: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  inviteTextDisabled: {
    backgroundColor: '#999',
  },
  inviteTextEnabled: {
    backgroundColor: '#377F67',
  },
  title: {
    fontFamily: 'Mukta Bold',
    fontSize: 18
  },
  divider: {
    marginTop: 15,
    marginBottom: 10
  },
  input: {
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    borderWidth: 1,
    borderRadius: 4,
    flex: 2
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    paddingTop: 5
  },
})