import { EditProfileScreen } from "../screens/home/edit-profile";
import { createStackNavigator } from "@react-navigation/stack";

const Stack = createStackNavigator();

export function StackLinkNavigation() {
  return (
    <Stack.Navigator
      id='StackLink'
      screenOptions={{
        headerShown: false,
      }}>

      <Stack.Screen name="EditProfile" component={EditProfileScreen} />
    </Stack.Navigator>
  )
}