import { create } from 'zustand'
import { PatientType } from '../../types/entities'

interface PatientsState {
  patients: PatientType[];
  setPatients: (patients: PatientType[]) => void;
  getPatient: (id: string) => PatientType | null;
}

export const usePatientStore = create<PatientsState>()((set, get) => ({
  patients: [],
  setPatients: (patients) => set(() => ({ patients })),
  getPatient: (id) => get().patients.find(p => p.id === id) || null
}))