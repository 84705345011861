import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import IconCalendar from '../lib/icons/calendar';
import IconPeople from '../lib/icons/people';
import IconMessage from '../lib/icons/messages';
import IconTasks from '../lib/icons/tasks';
import { CalendarScreen } from "../screens/calendar/home";
import { TasksScreen } from "../screens/tasks";
import { HeaderComponent } from "../components/header";
import { PatientsNavScreen } from "../screens/people";
import { MessagesScreen } from "../screens/messages";
import useData from "../lib/data/useData";
import { CalendarNavScreen } from "../screens/calendar";

const Tab = createBottomTabNavigator();

export function TabsNavigation() {
  const { user } = useData()

  return (
    <Tab.Navigator id='Primary' screenOptions={({ route, navigation }) => ({
      headerShadowVisible: false,
      title: '',
      /*header(props) {
        return (<HeaderComponent />)
      },*/
      headerShown: false,
      tabBarShowLabel: false,
      tabBarStyle: {
        display: !user ? 'none' : 'flex',
        position: 'absolute',
        backgroundColor: '#fff',
        shadowColor: '#666',
        shadowOpacity: .3,
        shadowRadius: 10,
        borderTopWidth: 0,
        height: 70,
        borderRadius: 10,
        margin: 10
      },
      tabBarActiveTintColor: '#377F67',
      tabBarInactiveTintColor: '#ACACAC',
      tabBarIcon: ({ color, size, focused }) => {
        if (route.name === 'Patients') {
          return <IconPeople color={color} />
        } else if (route.name === 'Calendar') {
          return <IconCalendar color={color} />
        } else if (route.name === 'Messages') {
          return <IconMessage color={color} />
        } else if (route.name === 'Tasks') {
          return <IconTasks color={color} />
        }
      }
    })}>
      <Tab.Screen name="Patients" component={PatientsNavScreen} />
      <Tab.Screen name="Calendar" component={CalendarNavScreen} />
      <Tab.Screen name="Messages" component={MessagesScreen} />
      <Tab.Screen name="Tasks" component={TasksScreen} />
    </Tab.Navigator>
  )
}