import React, { useState, useEffect } from 'react'
import SearchPatients from './search'
import ListPatients from './list'
import { PatientType, TeamType } from '../../lib/types/entities'
import { View } from 'react-native-ui-lib'

export interface IPatient extends PatientType {
  team: TeamType
}

export default function Search({ patients = [], onSelectPatient }: { patients: IPatient[], onSelectPatient: (patient: IPatient) => void }) {
  const [items, setItems] = useState<IPatient[]>(patients)

  const onChangeHandler = (value: string) => {
    if (!value) {
      setItems(patients)
      return
    }
    const getPatients = async () => { 
      const filteredItems = patients.filter((item: any) => {
        const fullName = `${item.first_name} ${item.last_name}`
        return fullName.toLowerCase().includes(value.toLowerCase())
      })
      setItems(filteredItems)
    }
    getPatients()
  }

  useEffect(() => {
    onChangeHandler('')
  }, [patients])

  return <View style={{ flex: 1 }}>
    <SearchPatients onChange={onChangeHandler} />
    <ListPatients onSelectPatient={onSelectPatient} items={items}/>
  </View>
}
