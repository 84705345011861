import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

export default function SvgComponent({ color = '#ACACAC' }) {
  return (
    <Svg width="18" height="20" viewBox="0 0 18 20" fill="none" >
        <Rect x="1" y="2.7998" width="16" height="16.2" rx="2" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M12.556 1V4.6" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M5.44397 1V4.6" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M1 8.2002H17" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  )
}