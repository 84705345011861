import { createStackNavigator } from '@react-navigation/stack';
import React from 'react'
import { PatientsListScreen } from './list-patients';
import EditPatientScreen from './edit';
import NewPatientScreen from './new';
import { PatientsDetailScreen } from './detail';
import { PeopleHomeScreen } from './home';
import { TherapistsListScreen } from './list-therapists';
import { TeamNewScreen } from '../team/new';
import TerapyTypeScreen from '../signup/data';
import { GoalsNewScreen } from '../goals/new';
import { GoalsListScreen } from '../goals/list';
import { GoalsEditScreen } from '../goals/edit';

const Stack = createStackNavigator();

export function PatientsNavScreen() {
  return (
    <Stack.Navigator
      id='StackPatients'
      screenOptions={{
        headerShadowVisible: false,
        headerShown: false,
        title: '',
      }}>
      <Stack.Screen name="Home" component={PeopleHomeScreen}/>
      <Stack.Screen name="ListTherapists" component={TherapistsListScreen}/>
      <Stack.Screen name="ListPatients" component={PatientsListScreen}/>
      <Stack.Screen name="EditPatient" component={EditPatientScreen}  options={{ title: 'Editar paciente' }}/>
      <Stack.Screen name="NewPatient" component={NewPatientScreen} options={{ title: 'Nuevo paciente' }}/>
      <Stack.Screen name="DetailPatient" component={PatientsDetailScreen} options={{ title: 'Paciente' }}/>
      <Stack.Screen name="NewTeam" component={TeamNewScreen} options={{ title: 'Nuevo equipo' }}/>
      <Stack.Screen name="TerapyType" component={TerapyTypeScreen} />
      <Stack.Screen name="Goals" component={GoalsNewScreen} options={{ title: 'Nuevo objetivo' }}/>
      <Stack.Screen name="GoalsList" component={GoalsListScreen} options={{ title: 'Objetivos' }}/>
      <Stack.Screen name="EditGoal" component={GoalsEditScreen} options={{ title: 'Editar objetivo' }}/>
    </Stack.Navigator>
  )
}