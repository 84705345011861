import { createContext, useMemo } from "react"
import type { PatientType, SessionType, TaskType, UserType } from "../types/entitites"
import { Session } from "@supabase/supabase-js";

export interface IDataContext {
  user: UserType | null;
  sessions: SessionType[] | null;
  tasks: TaskType[] | null;
  userSession: Session | null;
  setUser: (user: UserType) => void;
}

export const DataContext = createContext<IDataContext | undefined>(undefined)

interface Props {
  children: React.ReactNode;
  session: Session;
  user: UserType;
  tasks: TaskType[];
  sessions: SessionType[];
  setUser: (user: UserType) => void;
}

export default function DataProvider({ children, setUser, session, user, tasks, sessions}: Props) {  
  const value = useMemo(() => {
    return {
      user,
      setUser,
      tasks,
      sessions,
      userSession: session
    };
  }, [user, tasks, sessions])

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  )
}
