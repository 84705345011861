import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function SvgComponent({ color = '#ACACAC' }) {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <Path fillRule="evenodd" clipRule="evenodd" d="M4.09243 14.1754C4.95197 13.3159 6.11776 12.833 7.33333 12.833H14.6667C15.8822 12.833 17.048 13.3159 17.9076 14.1754C18.7671 15.035 19.25 16.2008 19.25 17.4163V19.2497C19.25 19.7559 18.8396 20.1663 18.3333 20.1663C17.8271 20.1663 17.4167 19.7559 17.4167 19.2497V17.4163C17.4167 16.687 17.1269 15.9875 16.6112 15.4718C16.0955 14.9561 15.396 14.6663 14.6667 14.6663H7.33333C6.60399 14.6663 5.90451 14.9561 5.38879 15.4718C4.87306 15.9875 4.58333 16.687 4.58333 17.4163V19.2497C4.58333 19.7559 4.17293 20.1663 3.66667 20.1663C3.16041 20.1663 2.75 19.7559 2.75 19.2497V17.4163C2.75 16.2008 3.23289 15.035 4.09243 14.1754Z" fill={color}/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M11 3.66634C9.48118 3.66634 8.24996 4.89756 8.24996 6.41634C8.24996 7.93512 9.48118 9.16634 11 9.16634C12.5187 9.16634 13.75 7.93512 13.75 6.41634C13.75 4.89756 12.5187 3.66634 11 3.66634ZM6.41663 6.41634C6.41663 3.88504 8.46865 1.83301 11 1.83301C13.5313 1.83301 15.5833 3.88504 15.5833 6.41634C15.5833 8.94765 13.5313 10.9997 11 10.9997C8.46865 10.9997 6.41663 8.94765 6.41663 6.41634Z" fill={color}/>
    </Svg>
  )
}