import { useState } from "react"
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import { TextInput } from 'react-native'
import { supabase } from "../../lib/supabase"
import { useToast } from "react-native-toast-notifications";
import { ButtonComponent } from "../../components/button"

export default function SignupScreen({ navigation }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const toast = useToast();

  const passwordAfirmHandler = (value: string) => {
    setPasswordValid(password === value)
  }

  async function signUpWithEmail() {
    setLoading(true)
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
    })

    if (error) {
      const message = error.message === 'Invalid login credentials' ? 'Los datos sin incorrectos' : 'Ocurrio un error, intenta nuevamente más tarde.'

      toast.show(message, {
        type: 'danger',
        duration: 5000
      });
    } else {
      navigation.navigate("Validate", { email })
    }
    setLoading(false)
  }

  const disabled = email === '' || password === '' || loading || !passwordValid

  return (
    <View style={styles.container}>
      <Image source={require('../../assets/ilus_2.png')} style={{ alignSelf: 'center', height: 258 - 20, width: 247 - 20 }}  resizeMode='contain'/>
      <Text style={styles.title}>Registro</Text>

      <View style={[styles.verticallySpaced]}>
        <TextInput
          onChangeText={(text) => setEmail(text)}
          value={email}
          placeholder="Email"
          autoCapitalize={'none'}
          style={styles.input}
        />
      </View>
      <View style={styles.verticallySpaced}>
        <TextInput
          onChangeText={(text) => setPassword(text)}
          value={password}
          secureTextEntry={true}
          placeholder="Contraseña"
          autoCapitalize={'none'}
          style={styles.input}
        />
      </View>
      <View style={styles.verticallySpaced}>
        <TextInput
          onChangeText={(text) => passwordAfirmHandler(text)}
          secureTextEntry={true}
          placeholder="Confirmar contraseña"
          autoCapitalize={'none'}
          style={styles.input}
        />
      </View>
      <ButtonComponent text="Continuar" loading={disabled} onPress={signUpWithEmail}/>
      <Pressable onPress={() => { navigation.navigate("Login") }}>
        <Text style={styles.link}>¿Ya tenés cuenta?</Text>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 50,
    backgroundColor: '#fff',
    flex: 1,
  },
  verticallySpaced: {
    paddingTop: 4,
    paddingBottom: 4,
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Mukta Bold',
    fontSize: 30,
    textAlign: 'center',
    color: '#474747'
  },
  input: {
    fontFamily: 'Mukta Regular',
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    borderWidth: 1,
    fontSize: 18,
    borderRadius: 4,
    width: 250,
  },
  link: {
    fontFamily: 'Mukta SemiBold',
    fontSize: 18,
    marginTop: 10,
    color: '#474747',
    textAlign: 'center',
  }
})