import { StyleSheet, Text, View } from "react-native"
import { FormGoal } from "../../components/form-goal"
import useData from "../../lib/data/useData"
import { useState } from "react"
import { GoalType, PatientType } from "../../lib/types/entities"
import { supabase } from "../../lib/supabase";

export function GoalsNewScreen({navigation, route}) {
  const [loading, setLoading] = useState(false)
  const patient = route.params.patient as PatientType
  const { userSession } = useData()

  async function onSubmitHandler(inputs: GoalType, type: string) {

    if (!loading) {
      setLoading(true)

      try {
        const { data, error } = await supabase.functions.invoke('goals', {
          headers: { Authorization: `Bearer ${userSession?.access_token}` },
          body: { goal: inputs, patient: patient.id, method: 'POST' }
        })

        if (!error) {  
          navigation.goBack()
        } else {
          console.log(error)
        }
      } catch (error) {
        console.log(error)
      }
      
      setLoading(false)
    }
  }
  
  return (
    <View style={styles.container}>
      <View style={styles.goalsContainer}>
        <FormGoal onSubmit={onSubmitHandler} patient={patient} loading={loading}/>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: 20,
  },
  title: {
    fontFamily: 'Mukta Regular',
    fontSize: 18,
  },
  goalsContainer: {
    flex: 2,
  },
})