import { Image, StyleSheet, TextInput, View } from 'react-native';

interface Props {
  onChange: (event: any) => void;
}

export default function SearchPatients({ onChange }: Props) {
  const onChangeHandler = (value: string) => {
    onChange(value)
  }

  return (
    <View style={styles.container}>
      <TextInput style={styles.input} placeholder='Buscar pacientes' onChangeText={onChangeHandler}/>
      <View style={styles.imgContainer}>
      <Image style={styles.img} source={require('../../assets/search.png')} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#DADADA',
    flexDirection: 'row',
    borderRadius: 4,
  },
  input: {
    flex: 1,
    fontSize: 16,
    paddingHorizontal: 16,
    paddingVertical: 5,
    fontFamily: 'Mukta Regular',
  },
  imgContainer: {
    backgroundColor: '#F3F8F6',
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    height: 16,
    width: 16,
  }
})