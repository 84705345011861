import { StyleSheet, Text, View, Image, Pressable, TextInput } from "react-native"
import Plus from '../../lib/icons/plus'
import useData from "../../lib/data/useData"
import { useEffect, useState } from "react"
import { supabase } from "../../lib/supabase";
import { PatientType, UserType } from "../../lib/types/entities";
import { ButtonComponent } from "../../components/button";
import { Divider } from "react-native-elements";
import { useTeamStore } from "../../lib/data/stores/teams";

export function TeamNewScreen({ navigation, route }) {
  const [name, setName] = useState<string>('')
  const [dirtyName, setDirtyName] = useState<boolean>(false)
  const { userSession, setUser } = useData()
  const { setActive, teams, setTeams } = useTeamStore()


  const onSaveName = async () => {
    const { data, error } = await supabase.functions.invoke('team', {
      headers: { Authorization: `Bearer ${userSession?.access_token}` },
      body: { name, method: 'POST' }
    })

    if (!error) {
      setDirtyName(false)

      setTeams([
        ...teams,
        data.team
      ])
      setActive(data.team.id)

      navigation.goBack()
    }
  }

  const onChangeName = (value: string) => {
    setDirtyName(true)
    setName(value)
  }

  return <View style={styles.container}>
    <Text style={styles.title}>Nombre</Text>
    <View style={styles.nameContainer}>
      <TextInput
        inputMode='text'
        onChangeText={onChangeName}
        value={name}
        style={styles.input} />
      <ButtonComponent text="Guardar" small loading={!dirtyName || !name} onPress={onSaveName}/>
    </View>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
    fontFamily: 'Mukta Regular',
  },
  input: {
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    borderWidth: 1,
    borderRadius: 4,
    flex: 2
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    paddingTop: 5
  },
  title: {
    fontFamily: 'Mukta Bold',
    fontSize: 18
  },
  therapistsTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  inviteText: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  inviteTextDisabled: {
    backgroundColor: '#999',
  },
  inviteTextEnabled: {
    backgroundColor: '#377F67',
  },
  divider: {
    marginTop: 15,
    marginBottom: 10
  }
})