import React, { useState } from 'react'
import { FlatList, Pressable, StyleSheet, Text, TextInput, View } from "react-native"
import useData from '../../lib/data/useData'
import { ButtonComponent } from '../../components/button'
import { supabase } from '../../lib/supabase'
import { Divider } from 'react-native-elements'
import { useTeamStore } from '../../lib/data/stores/teams'
import { useTherapistStore } from '../../lib/data/stores/therapists'
import { TherapistRow } from '../../components/therapist-row'

export function TherapistsListScreen({navigation, route}) {
  const { userSession } = useData()
  const { therapists } = useTherapistStore()
  
  const [showInvite, setShowInvite] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')

  const { active } = useTeamStore.getState()

  const onSendInvitation = async () => {
    const { data, error } = await supabase.functions.invoke('invitation', {
      headers: { Authorization: `Bearer ${userSession?.access_token}` },
      body: { team: active, email, method: 'POST' }
    })

    if (!error) {
      setShowInvite(false)
      setEmail('')
    }
  }

  const showInputInvitation = () => {
    setShowInvite((prevState) => !prevState)
  }

  return <View style={styles.container}>
    <View>
      <View style={styles.therapistsTitle}>
        <Text style={styles.title}>Terapeutas</Text>
        <Pressable onPress={showInputInvitation}>
          <Text style={[styles.inviteText, !showInvite ? styles.inviteTextEnabled : styles.inviteTextDisabled]}>Invitar</Text>
        </Pressable>
      </View>
      {showInvite && <View style={styles.nameContainer}>
        <TextInput
          inputMode='text'
          onChangeText={setEmail}
          placeholder="Email"
          value={email}
          style={styles.input} />
        <ButtonComponent text="Enviar" small loading={!email} onPress={onSendInvitation}/>
      </View>}
      <View style={{ flexDirection: 'row', gap: 2 }}>
        <FlatList
          style={{ width: "100%" }}
          data={therapists}
          renderItem={({ item }) => <TherapistRow {...item} big/>}
          keyExtractor={(item) => item?.id}
        />
      </View>
    </View>
    <Divider style={styles.divider} />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: 20,
    paddingTop: 40,
  },
  therapistsTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  inviteText: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  inviteTextDisabled: {
    backgroundColor: '#999',
  },
  inviteTextEnabled: {
    backgroundColor: '#377F67',
  },
  title: {
    fontFamily: 'Mukta Bold',
    fontSize: 18
  },
  divider: {
    marginTop: 15,
    marginBottom: 10
  },
  input: {
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#DADADA',
    borderWidth: 1,
    borderRadius: 4,
    flex: 2
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    paddingTop: 5
  },
})