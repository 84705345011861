import React, { useCallback, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native"
import useData from '../../lib/data/useData'
import { GoalType, PatientType } from '../../lib/types/entities'
import { supabase } from '../../lib/supabase'
import 'react-native-gesture-handler'
import { useFocusEffect } from '@react-navigation/native'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { Goal } from '../../components/goal'
import { Divider } from 'react-native-elements'
import Plus from '../../lib/icons/plus'
dayjs.locale('es')

export function GoalsListScreen({navigation, route}) {
  const [loading, setLoading] = useState(false)
  const { userSession } = useData()
  const [goals, setGoals] = useState<GoalType[] | null>(null)
  const patient = route.params.patient as PatientType  

  useFocusEffect(
    useCallback(() => {
      const getData = async () => {
        try {
          const { data, error } = await supabase.functions.invoke('goals', {
            headers: { Authorization: `Bearer ${userSession?.access_token}` },
            body: { patient: patient?.id, method: 'GET' }
          })

          if (!error) {
            setGoals(data?.goals || [])
          }
        } catch (error) {
          console.log(error)
        }
      }

      if (patient) {
        getData()
      }
    }, [patient, userSession])
  )

  return <View style={styles.container}>
      <View style={styles.goalsContainer}>
        <View style={styles.header}>
          <Text style={styles.title}>Objetivos</Text>
          <Pressable onPress={() => navigation.navigate('Goals', { patient })}>
            <Plus />
          </Pressable>
        </View>
        <View style={styles.scroll}>
          <ScrollView>
            {goals?.map((goal: GoalType) => <Goal goal={goal}/>)}
          </ScrollView>
        </View>
      </View>
    </View>
 
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    padding: 20,
    flex: 1,
    fontFamily: 'Mukta Regular',
  },
  containerHeader: {
    flex: 1
  },
  scroll: {
    paddingTop: 10,
    flex: 1
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#474747',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#474747',
  },
  data: {
  },
  dynamicContentContainer: {
  },
  goalsContainer: {
    flex: 5,
  },
  buttonDrawer: {
    alignItems: 'center',
    flex: 0
  },
  bottomSheet: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.50,
    shadowRadius: 4.65,
    elevation: 6,
  },
  recordDate: {
    fontFamily: 'Mukta Regular',
    color: '#ccc'
  }, 
  addText: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: '#377F67',
  },
})
