import React, { useCallback, useState } from 'react'
import { FlatList, Pressable, StyleSheet, Text, View } from "react-native"
import useData from '../../lib/data/useData'
import { GoalType, PatientType } from '../../lib/types/entities'
import Pencil from '../../lib/icons/pencil'
import 'react-native-gesture-handler';
import { useFocusEffect } from '@react-navigation/native';
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { Card } from 'react-native-ui-lib'
import { Divider } from 'react-native-elements'
import { GoalRow } from '../../components/goal-row'
import { supabase } from '../../lib/supabase'
dayjs.locale('es')


export function PatientsDetailScreen({navigation, route}) {
  const [loading, setLoading] = useState(false)
  const [goals, setGoals] = useState<GoalType[] | null>(null)
  const { userSession } = useData()
  const patient = route.params.patient as PatientType

  useFocusEffect(
    useCallback(() => {
      const getData = async () => {
        try {
          const { data, error } = await supabase.functions.invoke('goals', {
            headers: { Authorization: `Bearer ${userSession?.access_token}` },
            body: { patient: patient?.id, method: 'GET' }
          })

          if (!error) {
            setGoals(data?.goals || [])
          }
        } catch (error) {
          console.log(error)
        }
      }

      if (patient) {
        getData()
      }
    }, [patient, userSession])
  )

  return <View style={styles.container}>
    <View style={styles.containerHeader}>
      <View style={styles.header}>
        <Text style={styles.title}>{patient.first_name} {patient.last_name}</Text> 
        <Pressable onPress={() => navigation.navigate('EditPatient', { patient })}>
          <Pencil />
        </Pressable>
      </View>
    </View>
    <View style={{ gap: 10, flexDirection: 'row', paddingVertical: 10 }}>
      <Card flex center onPress={() => navigation.navigate('GoalsList', { patient })}>
        <Text>Objetivos</Text>
        <Divider />
        <FlatList
          style={{ width: "100%" }}
          data={goals}
          renderItem={({ item }) => <GoalRow {...item} />}
          keyExtractor={(item) => item?.id}
        />
      </Card>
      <Card flex center onPress={() => navigation.navigate('DocumentsList', { patient })}>
        <Text>Documentos</Text>
      </Card>
    </View>
    <View style={{ gap: 10, flexDirection: 'row', paddingVertical: 10 }}>
      <Card flex center onPress={() => navigation.navigate('MessagesList', { patient })}>
        <Text>Mensajes</Text>
      </Card>
      <Card flex center onPress={() => navigation.navigate('TasksList', { patient })}>
        <Text>Tareas</Text>
      </Card>
    </View>
  </View>
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    padding: 20,
    flex: 1,
  },
  containerHeader: {
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#474747',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#474747',
  },
})
