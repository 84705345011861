import { Pressable, StyleSheet } from "react-native"
import { LocaleConfig} from 'react-native-calendars';
import { CalendarComponent } from "../../components/calendar";
import { View, Text } from "react-native-ui-lib";
import { useState } from "react";

LocaleConfig.locales['es'] = {
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Spetiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  monthNamesShort: ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'],
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
  dayNamesShort: ['Dom.', 'Lun.', 'Mar.', 'Mir.', 'Jue.', 'Vie.', 'Sab.'],
  today: "Hoy"
};
LocaleConfig.defaultLocale = 'es'

export function CalendarHomeScreen({navigation, route}) {
  const [ weekView, setWeekView ] = useState<boolean>(true)

  const onChangeDayHandler = () => {
    setWeekView(true)
  }

  return <View style={styles.container}>
    <View row spread>
      <View row>
        <Pressable style={[styles.button, weekView && styles.buttonSelected]} onPress={() => setWeekView(true)}>
          <Text>Semana</Text>
        </Pressable>
        <Pressable style={[styles.button, !weekView && styles.buttonSelected]} onPress={() => setWeekView(false)}>
          <Text>Mes</Text>
        </Pressable>
      </View>

      <Pressable onPress={() => { navigation.navigate("NewSession", { team: '' }) }}>
        <Text style={[styles.inviteText, styles.inviteTextEnabled]}>Agregar</Text>
      </Pressable>
    </View>
    <CalendarComponent weekView={weekView} onChangeDayHandler={onChangeDayHandler}/>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: 20,
    paddingBottom: 50
  },
  button: {
    padding: 5,
  },
  buttonSelected: {
    backgroundColor: '#D1E1DD',
    borderRadius: 20,
  },
  inviteText: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 6,
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  inviteTextEnabled: {
    backgroundColor: '#377F67',
  },
})